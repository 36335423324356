var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('g-table',{ref:"transaction-table",attrs:{"items":_vm.items,"columns":_vm.tableColumns,"is-busy":_vm.isTableBusy,"noAction":true,"perPage":"25","totalRows":_vm.totalRows,"createButton":{ visiable: true },"searchInput":{ visiable: true }},on:{"on-create":function (v) {
        this$1.$router.push({ name: 'plan-new' });
      }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-nowrap d-inline-flex"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('delete')),expression:"$t('delete')",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"data-action-type":"delete","variant":"flat-danger","size":"sm"},on:{"click":function (v) {
              _vm.remove(item);
            }}},[_c('feather-icon',{staticClass:"danger",attrs:{"icon":"TrashIcon","stroke":"red","id":("invoice-row-" + (item.id) + "-delete-icon")}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
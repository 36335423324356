<template>
  <b-card>
    <g-table
      ref="transaction-table"
      :items="items"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          this.$router.push({ name: 'plan-new' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap d-inline-flex">
          <!-- <b-form-checkbox
            checked="true"
            class="custom-control-primary mt-25"
            name="check-button"
            v-model="item.isActive"
            @change="
              (v) => {
                activation(item);
              }
            "
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="EyeIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="EyeOffIcon" />
            </span>
          </b-form-checkbox> -->
          <b-button
            data-action-type="delete"
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                remove(item);
              }
            "
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import { plans } from '@/libs/acl/Lookups';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      items: [],
      plansCategories: plans,
      totalVals: 0,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'id', label: this.$t('code'), sortable: false },
        { key: 'arabicName', label: this.$t('name'), sortable: false },
        {
          key: 'planCategoryArabicName',
          label: this.$t('planCategory'),
          sortable: false,
        },
        {
          key: 'price',
          label: this.$t('price'),
          sortable: false,
        },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    activation(item) {
      if (item.isActive === true) {
        this.create({
          url: `plans/activate/${item.id}?category=${item.planCategoryEnglishName}`,
        }).then(() => {
          this.doneAlert({ text: this.$t('changeSuccessfully') });
          this.getPlans();
        }).catch(({ data }) => {
        this.doneAlert({ text: this.$t(data.detail), type: 'error' });
        this.getPlans();
        });
      } else {
        this.create({
          url: `plans/deactivate/${item.id}?category=${item.planCategoryEnglishName}`,
        }).then(() => {
          this.doneAlert({ text: this.$t('changeSuccessfully') });
          this.getPlans();
        }).catch(({ data }) => {
        this.doneAlert({ text: this.$t(data.detail), type: 'error' });
        this.getPlans();
      });
      }
    },
    getPlans() {
      this.get({ url: 'plans' }).then((data) => {
        this.items = data;
        this.items.forEach((item) => {
          const planCategory = this.plansCategories.find(
            (val) => val.id === item.planCategory
          ) || {
            arabicName: '',
            englishName: '',
          };
          item.planCategoryArabicName = planCategory.arabicName;
          item.planCategoryEnglishName = planCategory.englishName;
        });
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({
            url: 'plans',
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getPlans();
          });
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'plan-edit',
        params: { id: item.id },
      });
    },
  },
};
</script>

<style></style>
